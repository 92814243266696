import { createSupabaseClient } from './assets/supabase/sb_general.js';
import { router } from './Router.js';

const supabase = createSupabaseClient();

router.beforeEach(async (to, from, next) => {
    next();
});

export default router;
