import { createRouter, createWebHistory } from 'vue-router';

export const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: [
        {
            path: '/login',
            name: 'login',
            component: () => import('./views/Login/Login.vue')
        },
        {
            path: '/:resourceId/branch/:branchId',
            name: 'ressource',
            component: () => import('./views/Resource/Resource.vue') // renommer cette page "Page"
        },
        {
            path: '/signup',
            name: 'signup',
            component: () => import('./views/Login/Signup.vue')
        },
        {
            path: '/404',
            name: 'NotFound',
            component: () => import('./views/NotFound/NotFound.vue')
        },
        {
            path: '/reset-password',
            name: 'ResetPassword',
            component: () => import('./views/Login/ResetPassword.vue')
        },
        {
            path: '/auth/jira/callback',
            name: 'jiraOAuthCallback',
            component: () => import('./views/JiraOAuthCallback/JiraOAuthCallback.vue')
        },
        {
            path: '/confirm-email',
            name: 'AcceptInvitation',
            component: () => import('./views/Login/InvitationAcceptance.vue')
        },
        {
            path: '/',
            name: 'default',
            component: () => import('./views/DefaultPage.vue')
        },
        {
            path: '/:pathMatch(.*)*',
            redirect: { name: 'default' }
        }
    ]
});
