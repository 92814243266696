import posthog from 'posthog-js';

export const posthogInstance = posthog.init('phc_IobvhvOm3uTkxNNIkAnM1JB0lEWQgfU9e3wYD51CKoa', {
    api_host: 'https://eu.i.posthog.com'
});

export default {
    install(app) {
        app.config.globalProperties.$posthog = posthogInstance;
    }
};

export function identifyPosthog(userId, userProperties = {}) {
    if (posthogInstance && userId) {
        posthogInstance.identify(userId, userProperties);
    }
}

export function captureEvent(eventName, eventProperties = {}) {
    if (posthogInstance) {
        posthogInstance.capture(eventName, eventProperties);
    }
}
