import { createApp } from 'vue';
import App from './App.vue';
import router from '.';
import * as Sentry from '@sentry/vue';
import posthogPlugin from './plugins/posthog';

const app = createApp(App);

const isLocalhost = window.location.href.includes('localhost:5173');
if (!isLocalhost) {
    app.use(posthogPlugin);
    Sentry.init({
        app,
        dsn: 'https://659740dbe62603d8087ccaa38fd72ae7@o4507582283972608.ingest.de.sentry.io/4507582286528592',
        environment: 'production', // Set your appropriate environment, mettre une condition sur l'url qui est dans le .env
        integrations: []
    });
}
app.use(router);

app.mount('#app');
