export function createElement(className, parentElement, type, id = null) {
    const div = document.createElement(type);
    div.className = className;
    parentElement.appendChild(div);
    if (id) {
        div.id = id;
    }
    return div;
}

export function createImage(className, parentElement, src, id = null) {
    const div = document.createElement('img');
    div.className = className;
    parentElement.appendChild(div);
    div.src = src;
    if (id) {
        div.id = id;
    }
    return div;
}

export function generateUuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (Math.random() * 16) | 0;
        const v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
}

export function getElementIdOfCurrentPage() {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/').filter(Boolean);

    let elementId = pathSegments[0];
    if (elementId === 'guest') {
        elementId = pathSegments[1];
    }
    return elementId;
}

export function getBranchIdofCurrentPage() {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/').filter(Boolean);

    let branchId = pathSegments[2];
    if (branchId === 'branch') {
        branchId = pathSegments[3];
    }
    return branchId;
}

export function getGuestElementId() {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/').filter(Boolean);
    let elementId = pathSegments[1];
    return elementId;
}

export function getGuestBranchId() {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/').filter(Boolean);
    let branchId = pathSegments[3];
    return branchId;
}

export function isFileReadOnly() {
    const url = new URL(window.location.href);
    const pathSegments = url.pathname.split('/').filter(Boolean);

    let elementId = pathSegments[0];
    if (elementId === 'guest') {
        return true;
    }
    const mainHeader = document.getElementById('main-header');
    if (mainHeader && mainHeader.dataset.readonly === 'true') {
        return true;
    } else {
        return false;
    }
}

export function createConfirmationModal(title, message, onConfirm, onCancel) {
    const overlay = createElement('settings-overlay', document.body, 'div');
    const modal = createElement('pop-up', overlay, 'div');

    modal.style.position = 'fixed';
    modal.style.top = '50%';
    modal.style.left = '50%';
    modal.style.transform = 'translate(-50%, -50%)';
    modal.style.padding = '20px';
    modal.style.zIndex = '1000';
    modal.style.width = '300px';

    const titleElement = createElement('h3', modal, 'h3');
    titleElement.textContent = title;
    titleElement.style.marginBottom = '15px';

    const messageElement = createElement('p', modal, 'p');
    messageElement.textContent = message;
    messageElement.style.marginBottom = '20px';

    const buttonContainer = createElement('flex-row justify-end gap-8', modal, 'div');

    const cancelButton = createElement('small-transparent-button selectable-item', buttonContainer, 'button');
    cancelButton.textContent = 'Cancel';
    cancelButton.onclick = () => {
        overlay.remove();
        if (onCancel) onCancel();
    };

    const confirmButton = createElement('small-red-button', buttonContainer, 'button');
    confirmButton.textContent = 'Delete';
    confirmButton.onclick = () => {
        overlay.remove();
        onConfirm();
    };

    overlay.onclick = (event) => {
        if (event.target === overlay) {
            overlay.remove();
            if (onCancel) onCancel();
        }
    };

    return overlay;
}

export const getColorName = (hexValue) => {
    return Object.keys(colors).find((key) => colors[key] === hexValue) || 'white';
};

export const getHexColor = (colorName) => {
    return colors[colorName] || colors.white;
};
